import { IconButton } from '@material-ui/core'
import { ModalContext } from '@root-gipro/context/modal-context'
import AddUncVoltage from '@root-gipro/modules/userProjects/components/Project/AddUncVoltage'
import { Voltage } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { tableNames } from '@root-gipro/modules/userProjects/models/consts/unc-add-right-table.const'
import { IRightTableUncProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { changeVoltage, filterBuffer, setUncFullInfo } from '@root-gipro/modules/userProjects/store/actions'
import { getTargetVoltageValues } from '@root-gipro/modules/userProjects/utils/helpers'
import { IUnc, IUncAddBuffer } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { ReactText, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputRightTable } from './InputRightTable'
import { PriorityHighRounded } from '@material-ui/icons'
import TransitionConfigurator from '../Modal/TransitionConfigurator'

export const RightTableUnc: React.FC<IRightTableUncProps> = ({ uncAddBuffer, deleteUnc }) => {
	const { setAnchorEl, anchorEl, accessoryRef } = useContext(ModalContext)
	const { loading, uncFullInfo,isUncCanDeleted } = useSelector((state: IState) => state.userProjects)
	const voltageRef = useRef<string>()
	const dispatch = useDispatch()
	const [anchorElUncConfig, setAnchorElUncConfig] = React.useState<null | HTMLElement>(null)
	const [uncInfo, setUncInfo] = useState<IUnc>()
	const bodyRowCellsRef = useRef(null);
	const prevBufferLengthRef = useRef(uncAddBuffer?.length)
	const addBufferObject = useMemo(() => {
		const obj: { [key: string]: any } = {}
		uncAddBuffer.forEach((elem: IUnc) => {
			const tableNum = elem.tableNum
			if (!obj[tableNum]) {
				obj[tableNum] = []
			}
			obj[tableNum].push(elem)
		})
		return obj
	}, [uncAddBuffer])
	useEffect(() => {
		if (!uncAddBuffer?.length) {
			dispatch(setUncFullInfo(null))
		}
	}, [uncAddBuffer])

	const handlerCloseConfig = () => {
		setAnchorElUncConfig!(null!)
	}
	useEffect(() => {
		if (uncAddBuffer.length > prevBufferLengthRef.current) {
		  if (bodyRowCellsRef.current) {
			//@ts-ignore
			bodyRowCellsRef.current.scrollTop = bodyRowCellsRef.current.scrollHeight; // Прокрутка вниз
		  }
		}
	
		prevBufferLengthRef.current = uncAddBuffer.length;
	  }, [uncAddBuffer]);
	return (
		<div className='table_wrapper'>
			<div className='table-right'>
				<div className='table-right__head'>
					<div className='head__item'>Расценка</div>
					<div className='head__item'>Кол-во</div>
					<div className='head__item'>Напр.</div>
					<div className='head__item'>
						<div className='del-icon '></div>
					</div>
				</div>
				<div
					className='table-right__body'
					// style={{ minHeight: '130px' }}
				>
					{addBufferObject &&
						Object.keys(addBufferObject).map((num, index) => (
							<div key={num} className='body__row-wrapper'>
								<div className='body__row__title'>
									<span className='table_num'>{`Таблица ${num === '8' ? 'C' : num === '8' ? '6' : num}. `}</span>
									{tableNames[num]}
								</div>
								<div className='body__row-cells' ref={bodyRowCellsRef} >

								
								{addBufferObject[num].map((unc: IUnc, index: ReactText) => {
									return (
										<div className='body__row-cell' key={unc.uncCell.code + unc.id + String(index)}>
											<div className='row-cell'>
												{unc.uncCell.code}
												{unc.uncMain?.complex_coeff &&
												unc.uncMain?.complex_coeff?.length > 0 ? (
													<div
														className='table_cell_warning'
														data-popup='config'
														onClick={e => {
															setUncInfo(unc)
															setAnchorElUncConfig!(e.currentTarget)
														}}
													>
														<PriorityHighRounded style={{ color: 'red', fontSize: '20px' }} />
													</div>
												) : (
													''
												)}
											</div>

											<div className='row-cell'>
												<InputRightTable key={unc.id} uncId={unc.id} uncCount={unc.count} />
											</div>
											<div className='row-cell'>
												<AddUncVoltage key={unc.uncCellId} voltageRef={voltageRef} dataAttr='volt-addBuff' unc={unc} />
											</div>
											<div className='row-cell'>
												<IconButton disabled={loading || !isUncCanDeleted}
														onClick={() => {
															deleteUnc(unc.id)
															if (uncFullInfo.id == unc?.uncCell?.id) {
																if (uncFullInfo?.uncCellAssist?.recommendUncCellIds) {
																	const recommendUncCellIds = uncFullInfo?.uncCellAssist?.recommendUncCellIds.flat()
	
																	// Фильтрую uncAddBuffer, исключая элементы с совпадающими id
																	const filteredUncAddBuffer = uncAddBuffer.filter(
																		item => !recommendUncCellIds.includes(item.uncCell.id)
																	)
																	if (uncFullInfo?.uncCellAssist?.requiredUncCellIds) {
																		dispatch(
																			filterBuffer(
																				filteredUncAddBuffer.filter(
																					item =>
																						!uncFullInfo?.uncCellAssist?.requiredUncCellIds.includes(item.uncCell.id)
																				)
																			)
																		)
																	}
																}
	
																dispatch(setUncFullInfo(null))
															}
														}}
												>
													<div className='del-icon'></div>
												</IconButton>
											</div>
										</div>
									)
								})}
						</div>
							</div>
						))}
				</div>
			</div>
			{anchorEl?.dataset.popup === 'volt-addBuff' && (
				<Voltage
					voltageRef={voltageRef}
					uncVoltage={voltageRef.current}
					targetVoltageValues={getTargetVoltageValues(uncAddBuffer, anchorEl?.dataset.uncid)}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl!}
					handler={(voltage: number) => {
						dispatch(changeVoltage(accessoryRef?.current as string, voltage, true))
						setAnchorEl!(null!)
					}}
				/>
			)}
			{anchorElUncConfig?.dataset.popup === 'config' && (
				<TransitionConfigurator
					anchorEl={anchorElUncConfig}
					setAnchorEl={setAnchorElUncConfig}
					handlerClose={handlerCloseConfig}
					coeff={uncInfo?.uncMain?.complex_coeff}
					notes={uncInfo?.uncMain?.coeff_notes}
				/>
			)}
		</div>
	)
}
