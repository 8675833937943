// ЭТОТ ФАЙЛ НЕ ТРОГАТЬ !!!!!

import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { store } from '@root-gipro/store'
import { handleAccessError } from '@root-gipro/utils/helpers/handleAccessError'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'
import axios, { AxiosInstance, Method } from 'axios'
import QS from 'qs'
import interseptor from './auth.interseptor'

const domain = (url: string) =>
	url === '/authorize' || url === '/auth/logout'
		? `https://${process.env.REACT_APP_ENV_AUTH}/api/v1`
		: `https://${process.env.REACT_APP_ENV}/ptk/v2`

const modeURL =
	process.env.NODE_ENV === 'development' ? `//localhost:4000/` : `https://${process.env.REACT_APP_ENV_AUTH}`
// Дефолтные заголовки с авторизацией
const fetchHeaders = () => {
	return {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('access_token')}`,
	}
}

const fetchAuthHeaders = () => ({
	'X-Requested-With': 'XMLHttpRequest',
	'Content-Type': 'application/json',
	Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
})

const authApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: `https://${process.env.REACT_APP_ENV_AUTH}/api/v1`,
	withCredentials: true,
})

const giproApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: `https://${process.env.REACT_APP_ENV}/ptk/v2/`,
	paramsSerializer: params => {
		return QS.stringify(params, { arrayFormat: 'comma' })
	},
})
// const giproAppAdmin: AxiosInstance = axios.create({
// 	headers: fetchAuthHeaders(),
// 	baseURL: `https://${process.env.REACT_APP_ENV}/ptk/v1/`,
// })

interseptor(giproApp)

// Функция делает запрос по указанному url,
// callback - функция, которая возвращает ответ к нужному нам виду

const fetchData = (
	url: string,
	callback: any,
	method: Method = 'GET',
	data?: any,
	headers: object = fetchHeaders()
) => {
	const request = giproApp({
		method,
		url: `${domain(url) + url}`,
		headers,
		data,
	})
		.then(res => {
			if (res.status >= 200 && res.status < 300) {
				if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
				return res.data
			} else {
				throw res
			}
		})
		.then(res => {
			if (process.env.NODE_ENV === 'development') {
				if (res.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.message,
						})
					)
					throw new Error(res)
				}
				if (res.error) {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.error,
						})
					)
					throw new Error(res)
				}
			}
			return callback(res)
		})
		.catch(err => {
			const { data } = err.response
			handlerErrors(data)
		})

	return request
}

const authorizeApi = (clientId = 'gipro') => {
	const data = {
		response_type: 'token',
		client_id: clientId,
		with: 'user',
	}

	return authApp({
		method: 'POST',
		url: '/authorize',
		data: JSON.stringify(data),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => {
			console.log(error)
		})
}

const logoutApi = () => {
	return authApp({
		method: 'POST',
		url: '/auth/logout',
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)
}

const commonProjectsApi = (params: any) => {
	return giproApp({
		method: 'GET',
		url: '/project-general-search',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}
const userProjectsApi = (params: any) => {
	return giproApp({
		method: 'GET',
		url: '/user-projects',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const userArchivesApi = (params: any) => {
	return giproApp({
		method: 'GET',
		url: '/user-projects?archive',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const getUncsCommonProjectApi = (params: any, id: string | number) => {
	return giproApp({
		method: 'GET',
		params,
		url: `/projects/${id}/uncs`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const getUncTechnicalSpecificationsCommonProjectApi = (id: string | number) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${id}/uncTechnicalSpecifications `,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const getSelectCommonProjectApi = (id: string | number) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${id}?with=years`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const downloadCommonProjectApi = (projectId: string | number, code: string, type: string) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${projectId}/${type}`,
		responseType: 'blob',
	})
		.then((res: any) => {
			const url = URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')

			document.body.appendChild(link)
			link.href = url
			link.download = `${code}.xlsx`
			link.click()
			link.remove()
		})
		.catch((error: any) => error)
}

const getUserInfoWorker = (email: string) =>
	giproApp({
		method: 'GET',
		url: `https://${process.env.REACT_APP_ENV}/ptk/v2/tech-support/userInfo?email=${email}`,
	})
		.then((res: any) => {
			return res.data
		})
		.catch((error: any) => error)

const addToUserProjectApi = (projectId: string | number) =>
	giproApp({
		method: 'POST',
		url: `/user-projects`,
		data: JSON.stringify({ projectId }),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)

const requestRgStatusesApi = (projectId: string | number) =>
	giproApp({
		method: 'POST',
		url: `/rg-statuses`,
		data: JSON.stringify({ projectId }),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)

export {
	domain,
	modeURL,
	fetchHeaders,
	fetchData,
	authorizeApi,
	commonProjectsApi,
	userProjectsApi,
	userArchivesApi,
	authApp,
	giproApp,
	logoutApi,
	getUncsCommonProjectApi,
	getSelectCommonProjectApi,
	downloadCommonProjectApi,
	addToUserProjectApi,
	requestRgStatusesApi,
	getUncTechnicalSpecificationsCommonProjectApi,
	fetchAuthHeaders,
	getUserInfoWorker,
}
