import React, { FC, useMemo } from 'react'
import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	makeStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { ICompany, IRegion, IState } from '@root-gipro/store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { ROLE_OWNER } from '@root-gipro/modules/AdminPanel/store/helpers'
import { setCompanyState, setOpenModalCreateCompany } from '@root-gipro/modules/AdminPanel/store/actions'

interface IAdminPanelTableCompanyRow {
	company: ICompany
	index: number
}

const useStyles = makeStyles(() => ({
	select: {
		maxWidth: 200,
		background: '#f5f5f5',
		borderRadius: '5px',
		'& .MuiSelect-select': {
			'&:hover': {
				backgroundColor: '#fff',
			},
		},
		paper: {
			width: 300,
		},
	},
}))

const AdminPanelTableCompanyRow: FC<IAdminPanelTableCompanyRow> = ({ company, index }) => {
	const { regions } = useSelector((state: IState) => state.public)
	const classes = useStyles()
	const roleId = localStorage.getItem('roleId')
	const dispatch = useDispatch()

	const updateCompanyData = () => {
		dispatch(setOpenModalCreateCompany({ open: true, mode: 'updateCompany' }))
		dispatch(setCompanyState(company))
	}

	const getCurrentRegions: any = useMemo(() => {
		try {
			if (regions) return regions?.filter((region: IRegion) => company?.regionIds?.indexOf(region.id) !== -1)
		} catch (error) {
			console.log(error)
		}
	}, [company, regions])

	const defaultLabel = () => {
		if (getCurrentRegions?.length > 1) {
			return `${getCurrentRegions[0]?.name} +${getCurrentRegions.length - 1} региона`
		} else if (getCurrentRegions[0]) {
			return getCurrentRegions[0]?.name
		} else {
			return ''
		}
	}

	return (
		<>
			<TableRow className='adminPanel__tableRow'>
				{roleId && Number(roleId) <= ROLE_OWNER ? (
					<TableCell className='adminPanelCell ' align='center'>
						<IconButton
							title='Редактировать'
							size='small'
							type='button'
							onClick={() => {
								updateCompanyData()
							}}
							aria-label='Изменить данные'
						>
							<EditIcon fontSize='small' />
						</IconButton>
					</TableCell>
				) : (
					''
				)}
				<TableCell className='adminPanelCell'>{++index}</TableCell>
				<TableCell className='adminPanelCell'>{company.name}</TableCell>
				<TableCell className='adminPanelCell' align={'left'}>
					<span title={company?.full_name}>{company?.full_name}</span>
				</TableCell>
				<TableCell className='adminPanelCell'>{company?.deflator_precision}</TableCell>
				<TableCell className='adminPanelCell'>
					{company?.ipr_start_year}-{company?.ipr_end_year}
				</TableCell>
				<TableCell className='adminPanelCell'>{company?.inn ? company?.inn : <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell'>{company?.kpp ? company?.kpp : <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell'>{company?.ogrn ? company?.ogrn : <>&mdash;</>}</TableCell>
				<TableCell className='adminPanelCell' align={'left'}>
					<span title={company?.okato}>{company?.okato ? company?.okato : <>&mdash;</>}</span>
				</TableCell>
				<TableCell className='adminPanelCell' align={'left'}>
					{getCurrentRegions?.length ? (
						<FormControl fullWidth>
							<InputLabel title={defaultLabel()} style={{ width: '100%' }} id='custom-select-label'>
								{defaultLabel()}
							</InputLabel>
							<Select
								labelId='custom-select-label'
								value={getCurrentRegions[0]?.name}
								IconComponent={() => null}
								displayEmpty
								className={classes.select}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								{getCurrentRegions?.map((region: IRegion) => (
									<MenuItem
										style={{ minWidth: '200px', maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' }}
										key={region?.id}
										value={region?.name}
									>
										<span title={region.name}>{region.name}</span>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : (
						<span>&mdash;</span>
					)}
				</TableCell>
			</TableRow>
		</>
	)
}

export default AdminPanelTableCompanyRow
