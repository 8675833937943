import { Modal } from '@root-gipro/shared/Modal/Modal'
import React, { FC } from 'react'
import InterPol from '@root-gipro/components/DeprecatedComponents/InterPol/InterPol'
import { SelectsTableData } from '@root-gipro/store/interfaces'
interface UncAddR1WindowProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	data: SelectsTableData[];
	handler(): void;
}
const UncAddR1Window: FC<UncAddR1WindowProps> = ({ data, anchorEl, setAnchorEl, handler }) => {
	const buttons = {
		PrimaryBtn: {
			handler: () => {
				handler()
				setAnchorEl(null!)
			},
			value: 'Готово',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl(null!)
			},
			value: 'Отмена',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Укажите значения',
			action: undefined,
		},
	]

	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<InterPol data={data} />
		</Modal>
	)
}

export default UncAddR1Window
