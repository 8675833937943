import '@root-gipro/modules/commonProjects/styles/Parameters.scss'
import { IUserProjectItemProps } from '@root-gipro/modules/userProjects/models/interfaces/user-projects-item.props'
import { CardFull, CardLeft, CardRight } from '@root-gipro/shared/Card'
import CardAmountOfFunding from '@root-gipro/shared/Card/Card/CardAmountOfFunding'
import CardCompanyName from '@root-gipro/shared/Card/Card/CardCompanyName'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardDescriptionUserProjects } from '@root-gipro/shared/Card/Card/CardDescriptionUserProjects'
import CardPeriods from '@root-gipro/shared/Card/Card/CardPeriods'
import CardRegions from '@root-gipro/shared/Card/Card/CardRegions'
import { prettyCost, timestampToDate } from '@root-gipro/utils/helpers/common'
import React, { useCallback } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

const Item: React.FC<IUserProjectItemProps> = ({ data }) => {
	const history = useHistory()
	const routeMatch = useRouteMatch()
	const currentUrl: string = routeMatch.path.slice(1)
	const createdDate = 'добавлено ' + timestampToDate(data.created_at)
	const archivedDate = data.archive ? 'в архиве с ' + timestampToDate(data.archived_at) : ''

	const totalInvestmentUncOffer = data.totalInvestmentUncOffer == null ? 0 : data.totalInvestmentUncOffer
	const valuationOfferForecast = data.valuationOfferForecast == null ? 0 : data.valuationOfferForecast

	const cardRightData = {
		totalInvestmentUncOffer: {
			title: 'Объём финансовых потребностей',
			valueRaw: data.totalInvestmentUncOffer ? data.totalInvestmentUncOffer : 0,
			value: data.totalInvestmentUncOffer ? prettyCost(data.totalInvestmentUncOffer / 10 ** 6) : '0.00',
		},
		valuationOfferForecast: {
			title: 'Оценка полной стоимости в прогнозных ценах соотв. лет (с НДС)',
			valueRaw: data.valuationOfferForecast ? data.valuationOfferForecast : 0,
			value: data.valuationOfferForecast ? prettyCost(data.valuationOfferForecast / 10 ** 6) : '0.00',
		},
		additionalFundingOffer: {
			title: 'Ненормируемые затраты',
			valueRaw: data.additionalFundingOffer ? data.additionalFundingOffer : 0,
			value: data.additionalFundingOffer ? prettyCost(data.additionalFundingOffer / 10 ** 6) : '0.00',
		},
		limit: {
			title: 'Превышение УНЦ',
			valueRaw: totalInvestmentUncOffer > valuationOfferForecast ? totalInvestmentUncOffer - valuationOfferForecast : 0,
			value:
				totalInvestmentUncOffer > valuationOfferForecast
					? prettyCost((totalInvestmentUncOffer - valuationOfferForecast) / 10 ** 6)
					: '0.00',
		},
	}

	const getProjectById = useCallback(
		(id: string | undefined) => {
			history.push({
				pathname: `/${currentUrl}#${id}`,
			})
		},
		[history]
	)

	return (
		<CardFull key={data.id} id={data.id} classes='detail-purchases mb-16'>
			<div className='card-full__item cardUserProjects'>
				<div className='card-full-wr'>
					<CardLeft>
						<div className='card-full__header'>
							<div className='card-full__header-left'>
								<Link
									to={`/${currentUrl}/project/${data.id}/parameters`}
									onClick={() => getProjectById(data.id)}
									className='card-container__code link-blue'
								>
									<span className='code'>{data.code}</span>
								</Link>
								<div className='card-full__header-icons'>
									<div className='card-full__header-lock'>
										<span className={`${data.locked ? 'lock-icon' : 'unlock-icon'}`}></span>
									</div>
								</div>
							</div>
						</div>
						<CardDate>{createdDate}</CardDate>
						<CardDate>{archivedDate}</CardDate>
						<CardRegions regionIds={data.regionIds} />

						<CardPeriods yearBegin={data.yearBegin} yearEndOffer={data.yearEndOffer} />
					</CardLeft>
					<CardRight>
						<CardCompanyName companyId={data.companyId ? data.companyId : null} />
						<div className='card-container__techConnect'>
							{data.techConnect ? 'Технологическое присоединение' : 'Инвестиционный проект'}
							<span style={{ color: '#1baaf0', fontWeight: 500, marginLeft: 16 }}>
								{data.onlyPIR ? 'Только ПИР' : null}
							</span>
						</div>
						<CardDescriptionUserProjects name={data.name} />
						<CardAmountOfFunding data={cardRightData} />
					</CardRight>
				</div>
			</div>
		</CardFull>
	)
}

export { Item }
