import React, { useState, useEffect } from 'react';
import './ProgressBar.scss';

interface ProgressBarProps {
  percent: string | number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent }) => {
  const [displayPercent, setDisplayPercent] = useState(0);

  useEffect(() => {
    if (Number(percent) - displayPercent > 50 || percent === 100) {
        setDisplayPercent(Number(percent));
        return;
      }
    const interval = setInterval(() => {
      if (displayPercent < Number(percent)) {
        setDisplayPercent((prev) => Math.min(prev + 1, Number(percent)));
      } else {
        clearInterval(interval);
      }
    }, 20);

    return () => clearInterval(interval);
  }, [percent, displayPercent]);

  const circleRadius = 45;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const strokeDashoffset =
    circleCircumference - (displayPercent / 100) * circleCircumference;

  return (
    <div className="progressContainer">
      <svg className="progressCircle" style={{ width: '23px', height: '23px' }} width="23" height="23" viewBox="0 0 100 100">
        <circle
          className="progressCircle-bg"
          cx="50"
          cy="50"
          r={circleRadius}
          strokeWidth="10"
        />
        <circle
          className="progressCircle-fg"
          cx="50"
          cy="50"
          r={circleRadius}
          strokeWidth="10"
          strokeDasharray={circleCircumference}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <div className="progressPercentage">{displayPercent}</div>
    </div>
  );
};

export default ProgressBar;
