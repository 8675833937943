import React, { useEffect, useState } from 'react'
import { Filter as FilterWrapper, FilterItemGroup } from '@root-gipro/shared/Filter'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'
import { formatDate } from '@root-gipro/utils/helpers/common'
import { isEqual } from 'lodash'
import { FilterProps } from '@root-gipro/modules/filter/models/props/filter.model'
import { useLocation } from 'react-router-dom'
import { IGroup, IRegion, ICompany, IFilial } from '@root-gipro/store/interfaces'

const Filter: React.FC<FilterProps> = ({
	resetFilter,
	refreshFilterState,
	showFilterResult,
	filterCountResult,
	filterState,
	purchasesType,
	uncInfo,
	purchasesMethods,
	purchasesStages,
	purchasesOkpd,
	purchasesOkved,
	filial,
	regions,
	groups,
	company,
	rgStatuses,
	publicationDates,
	purchasePublishDates,
	uncThs,
	uncThDescriptions,
	uncMains,
	policesRGCO,
	uncTechnicalSpecifications,
}) => {
	const [currentFilter, setCurrentFilter] = useState<boolean>(false)
	const [filials, setFilials] = useState<IFilial[]>([])

	const location = useLocation()
	const url: string = location.pathname.slice(1)

	useEffect(() => {
		setCurrentFilter(false)
	}, [url])

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if ((value !== undefined || value !== '') && !isEqual(value, (filterState as Record<string, string>)[fieldName])) {
			if (fieldName === 'uncTechnicalSpecification.name') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.paramName') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
			}
			if (filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name']?.length > 1) {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'companyId') {
				const companyId = company.filter(c => c.name == value)[0]?.id
				setFilials(filial.filter(f => f.companyId === companyId))
			} else if (fieldName !== 'filialId') {
				setFilials([])
			}
			refreshFilterState!(fieldName, value, url)
		}
	}

	return (
		<FilterWrapper resultCnt={filterCountResult} resetFilterHandler={resetFilter} showFilterResult={showFilterResult}>
			<FilterItemGroup tagsFilter={['user-projects', 'user-archive']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Дата'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='created_at.min'
					fieldNameEnd='created_at.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='date'
					dateViews={['date', 'month', 'year']}
				/>
				<FilterItem
					name='Версия'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='archived_at.min'
					fieldNameEnd='archived_at.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='date'
					dateViews={['date', 'month', 'year']}
				/>
				<FilterItem
					name='Отсутствует расчет по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='unc_calculation'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Есть ограничение по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='unc_limitation'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только ПИР'
					filterState={filterState}
					mode='checkbox'
					fieldName='onlyPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Тех.прис.'
					filterState={filterState}
					mode='checkbox'
					fieldName='techConnect'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['user-projects', 'user-archive']} name='Организация'>
				<FilterItem
					isMultiple
					placeholder='Компания'
					filterState={filterState}
					mode='select'
					fieldName={'companyId'}
					setValue={refreshFilterValue}
					items={company?.map((company: ICompany) => company.name).sort() || []}
				/>
				<FilterItem
					isMultiple
					placeholder='Филиал'
					filterState={filterState}
					mode='select'
					fieldName={'filialId'}
					setValue={refreshFilterValue}
					items={filials.map((el: IFilial) => el.name)}
				/>
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только имеющие расценки по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsUNC'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Организация'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'company_id' : 'groupId'}
					setValue={refreshFilterValue}
					items={
						currentFilter
							? company?.map((company: ICompany) => company.name).sort() || []
							: groups?.map((group: IGroup) => group.name).sort() || []
					}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Регион'>
				<FilterItem
					isMultiple
					placeholder='Регион'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? `region_code` : 'regionId'}
					setValue={refreshFilterValue}
					items={regions?.map((region: IRegion) => region.name) || []}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Период'>
				<FilterItem
					isMultiple
					placeholder='Публикация в открытых источниках'
					filterState={filterState}
					mode='select'
					fieldName='publicationDate'
					setValue={refreshFilterValue}
					items={publicationDates.map((date: string) => formatDate(date, !currentFilter)!) || []}
				/>
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='yearBegin.min'
					fieldNameEnd='yearEndOffer.max'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
				<FilterItem
					name='Добавление карточки проекта'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='cardCreated.min'
					fieldNameEnd='cardCreated.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month', 'date']}
				/>
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['common-projects', 'favorites-common-projects']}
				name='Оценка полной стоимости, млн руб. с НДС'
			>
				<FilterItem
					name='По УНЦ'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationUncOfferForecast.min' : 'valuationUncOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationUncOfferForecast.max' : 'valuationUncOfferForecast.max'}
					setValue={refreshFilterValue}
					isDisable={
						currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast'])
							: Boolean(filterState['valuationUncOfferForecast'])
					}
					withCheckbox={{
						checked: currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast']) || false
							: Boolean(filterState['valuationUncOfferForecast']) || false,
						setChecked: (value: boolean) =>
							currentFilter
								? refreshFilterValue('project.valuationUncOfferForecast', value)
								: refreshFilterValue('valuationUncOfferForecast', value),
						label: 'Без расчёта по УНЦ',
						checkboxChange: () =>
							currentFilter
								? refreshFilterValue(
										'project.valuationUncOfferForecast',
										filterState['project.valuationUncOfferForecast']
								  )
								: refreshFilterValue('valuationUncOfferForecast', filterState['valuationUncOfferForecast']),
					}}
				/>
				<br />
				<br />
				<FilterItem
					name='В прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationOfferForecast.min' : 'valuationOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationOfferForecast.max' : 'valuationOfferForecast.max'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Остаток финансирования в прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.balanceOffer.min' : 'balanceOffer.min'}
					fieldNameEnd={currentFilter ? 'project.balanceOffer.max' : 'balanceOffer.max'}
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['common-projects', 'favorites-common-projects']}
				name='Полная сметная стоимость согласно ПСД (с НДС)'
			>
				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Стоимость'
						filterState={filterState}
						mode='range-numbers'
						fieldNameStart={currentFilter ? 'project.estimatedOfferCurrent.min' : 'estimatedOfferCurrent.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferCurrent.max' : 'estimatedOfferCurrent.max'}
						setValue={refreshFilterValue}
						isDisable={
							currentFilter
								? Boolean(filterState['project.estimatedOfferYM'])
								: Boolean(filterState['estimatedOfferYM'])
						}
					/>
				)}

				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Дата составления'
						filterState={filterState}
						mode='dates-range'
						fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
						setValue={refreshFilterValue}
						dateOpenTo='year'
						dateViews={['year', 'month']}
					/>
				)}

				<FilterItem
					name='Без ПСД'
					filterState={filterState}
					mode='checkbox'
					fieldName='estimatedOfferYM'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
		</FilterWrapper>
	)
}
export default Filter
