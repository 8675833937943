import React, { ReactText, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import CardCommonProject from '@root-gipro/modules/commonProjects/components/CardProject/CardCommonProject'
import { appendCommonProjects, getSelectCommonProject } from '@root-gipro/modules/commonProjects/store/actions'
import Loader from '@root-gipro/shared/Loader/Loader'
import ReportLoader from '@root-gipro/shared/Loader/ReportLoader'
import { ICommonProject, ICommonProjectsListProps, IRegion } from '@root-gipro/store/interfaces'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import { useHistory } from 'react-router-dom'

const CommonProjectsList: React.FC<ICommonProjectsListProps> = ({ projects, toggleType }) => {
	const { groups, company, regions } = useSelector((state: IState) => state.public)
	const { isFavorites, loading, reporting } = useSelector((state: IState) => state.commonProjects)
	let { paginationCounter } = useSelector((state: IState) => state.commonProjects)
	const listRef = useRef<HTMLDivElement>(null)

	const dispatch = useDispatch()
	const history = useHistory()

	const getRegionProject = (firstRegionId: string) => {
		const regionIdx = regions?.findIndex((region: IRegion) => region.id === firstRegionId)
		return regions && regions[regionIdx]?.name
	}

	const getProjectById = useCallback(
		(id: ReactText) => {
			dispatch(getSelectCommonProject(id))
			history.push({
				pathname: `/common-projects#${id}`,
			})
		},
		[history]
	)
	const handlerFavoritesActions = useCallback(() => undefined, [])

	const checkAndLoadMore = () => {
		if (!listRef.current) return
		const { scrollHeight } = listRef.current
		const height = listRef.current.getBoundingClientRect().height

		if (!loading && !isFavorites && scrollHeight <= height + 5) {
			dispatch(appendCommonProjects(++paginationCounter))
		}
	}

	useEffect(() => {
		const handleResize = () => {
			checkAndLoadMore()
		}

		window.addEventListener('resize', handleResize)
		checkAndLoadMore() // Проверка при монтировании

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [loading, isFavorites, paginationCounter])

	const onScroll = (e: any) => {
		const scrollTop = e.target.scrollTop
		const scrollHeight = e.target.scrollHeight
		const height = e.target.getBoundingClientRect().height

		if (!loading && !isFavorites && scrollTop && scrollHeight - scrollTop <= height + 10) {
			dispatch(appendCommonProjects(++paginationCounter))
		}
	}

	return (
		<>
			<div>{reporting && <ReportLoader />}</div>
			<ScrollHandler />
			<div ref={listRef} className='project__list' onScroll={onScroll}>
				{projects &&
					projects.map((item: ICommonProject) => (
						<CardCommonProject
							key={item.id}
							toggleType={toggleType}
							project={item}
							groups={groups}
							company={company}
							region={getRegionProject(item.firstRegionId)}
							classIcon='common-project-icon'
							getProjectById={getProjectById}
							handlerFavoritesActions={handlerFavoritesActions}
						/>
					))}
				<div>{loading && <Loader />}</div>
			</div>
		</>
	)
}

export default React.memo(CommonProjectsList)
