import { IFilterState, IFilterRates, IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { IPurchasesList } from '@root-gipro/modules/purchases/models/interfaces/purchases-list-request'
import { ICommonProject } from '@root-gipro/store/interfaces'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import { Action } from 'redux'

export interface IRefreshList extends ICommonProject, IPurchasesList {}
// export type { IRefreshList }

export const setCallbacks = (
	getInitList: () => void,
	showFilterResult: (params: IParams) => void,
	refreshList: (projects: IRefreshList[], isFavorite: boolean) => Action,
	toggleLoadingList: (loading: boolean) => void
) => ({
	type: FilterActions.SET_CALLBACKS,
	getInitList,
	showFilterResult,
	refreshList,
	toggleLoadingList,
})
export const filtersRequest = (url: string) => ({
	type: FilterActions.FILTER_REQUEST,
	url,
})
export const copyProjects2Calc = () => ({
	type: FilterActions.COPY_PROJECTS_2_CALC,
})
export const showBtnCopyProjects2Calc = (showBtnCopyProjects2Calc: boolean) => ({
	type: FilterActions.SHOW_BTN_COPY_PROJECTS_2_CALC,
	showBtnCopyProjects2Calc,
})
export const filterSetResult = () => ({
	type: FilterActions.FILTER_SET_RESULT,
})
export const setFilterSortParams = (params: IParams, url: string) => ({
	type: FilterActions.SET_FILTER_SORT_PARAMS,
	params,
	url,
})

export const refreshFilterState = (
	fieldName: string,
	value: string,
	url: string,
	convertValue: (fieldName: string, value: string) => { fieldName: string; value: string }
) => ({
	type: FilterActions.REFRESH_FILTER_STATE,
	fieldName,
	value,
	url,
	convertValue,
})

export const showFilterResult = (url: string, filter?: boolean) => ({
	type: FilterActions.SHOW_FILTER_RESULT,
	url,
	filter,
})

export const resetFilterState = (url: string) => ({
	type: FilterActions.RESET_FILTER_STATE,
	url,
})

export const setInitFilterState = (state: any) => ({
	type: FilterActions.SET_INIT_FILTER_STATE,
	state,
})

export const setFilterState = (state: IFilterState) => ({
	type: FilterActions.SET_FILTER_STATE,
	state,
})

export const setFilterResultCount = (count: number, url: string) => ({
	type: FilterActions.SET_FILTER_RESULT_COUNT,
	count,
	url,
})

export const setFilterCommonProjectsRates = (data: IFilterRates) => ({
	type: FilterActions.SET_FILTER_COMMON_PROJECTS_RATES,
	data,
})
export const resetFilterCommonProjectsRates = () => ({
	type: FilterActions.RESET_FILTER_COMMON_PROJECTS_RATES,
})
