import React, { useState } from 'react'
import { Collapse } from '@material-ui/core'
import { FilterItemGroupProps } from '@root-gipro/shared/Filter/models/filter-item-group.props'

const FilterItemGroup: React.FC<FilterItemGroupProps> = ({ name, children }) => {
	const [isExpanded, setOpen] = useState<boolean>(false)

	return (
		<div className='aside-filter__item'>
			<div className='aside-filter__item-head' onClick={() => setOpen(!isExpanded)}>
				<h4 className={`aside-filter__item-select `}>{name}</h4>
				<span className={`dropdown-icon-bold ${isExpanded ? 'open' : ''}`} />
			</div>
			<Collapse in={isExpanded} unmountOnExit className='aside-filter__item-dropdown'>
				{children}
			</Collapse>
		</div>
	)
}

export default FilterItemGroup
