import { downloadCheckWarns } from '@root-gipro/modules/userProjects/store/actions'
import { filterWidthWindow } from '@root-gipro/modules/userProjects/utils/helpers'
import Loader from '@root-gipro/shared/Loader/Loader'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { ISectionIds } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { FC, MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MassErrorList from './MassErrorList'
interface CheckProjectProps {
	title?: string;
	handler: () => void;
	sectionIds: ISectionIds[];
	sectionIdsArr: number[];
	accessoryRef: MutableRefObject<string | undefined> | undefined;
	accessory: string | undefined;
	setAccessory?(string: string): void;
	setAnchorEl(event: HTMLElement): void;
	anchorEl: (EventTarget & HTMLElement) | null | undefined;
}
const MassErrors: FC<CheckProjectProps> = ({
	handler,
	setAnchorEl,
	sectionIds,
	sectionIdsArr,
	accessoryRef,
	accessory,
	setAccessory,
	anchorEl,
}) => {
	const { massErrors, massCheckLoader, showLoader } = useSelector((state: IState) => state.userProjects)

	const dispatch = useDispatch()

	const filterButtonOptions = (step: string) => {
		return {
			PrimaryBtn: {
				handler: () => {
					setAnchorEl!(null!)
				},
				value: 'Готово',
			},
			SecondaryBtn: {
				handler: () => {
					dispatch(downloadCheckWarns(sectionIdsArr))
				},
				value: showLoader ? 'Загрузка...' : 'Скачать Excel',
				disabled: massCheckLoader,
			},
		}
	}

	return (
		<Modal
			keyBtn={accessory}
			buttonOptions={accessory ? filterButtonOptions(accessory) : null}
			setAnchorEl={setAnchorEl}
			anchorEl={anchorEl}
			title={undefined}
			widthWindow={filterWidthWindow(accessory)}
		>
			<div style={{ maxHeight: '500px', overflowY: 'auto' }}>
				{massCheckLoader ? (
					<Loader />
				) : (
					massErrors?.length &&
					massErrors.map(projectErrors => (
						<div key={projectErrors.id}>
							<MassErrorList
								projectErrors={projectErrors.messages}
								userProjectCode={projectErrors.userProjectCode}
								sectionIds={sectionIds}
							/>
						</div>
					))
				)}
			</div>
		</Modal>
	)
}
export default MassErrors
