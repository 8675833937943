import { IGroup, ICompany, IRegion, IRgStatuses, IUncMain, IUncThDescriptions, IUncThs, IFilial } from '../interfaces'

enum PublicType {
	FETCH_PUBLIC_INFO = 'FETCH_PUBLIC_INFO',
	FETCH_PUBLIC_USER_PROJECT_INFO = 'FETCH_PUBLIC_USER_PROJECT_INFO',
	FETCH_PUBLIC_UNC_DESCRIPTIONS = 'FETCH_PUBLIC_UNC_DESCRIPTIONS',
	SET_PUBLIC_INFO = 'SET_PUBLIC_INFO',
	SET_PUBLIC_USER_PROJECT_INFO = 'SET_PUBLIC_USER_PROJECT_INFO',
	SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS = 'SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS',
	GET_PURCHASES_METHODS = 'GET_PURCHASES_METHODS',
	SET_PURCHASES_METHODS = 'SET_PURCHASES_METHODS',

	GET_PURCHASES_STAGES = 'GET_PURCHASES_STAGES',
	SET_PURCHASES_STAGES = 'SET_PURCHASES_STAGES',
	GET_PURCHASES_OKPD = 'GET_PURCHASES_OKPD',
	SET_PURCHASES_OKPD = 'SET_PURCHASES_OKPD',
	GET_PURCHASES_OKVED = 'GET_PURCHASES_OKVED',
	SET_PURCHASES_OKVED = 'SET_PURCHASES_OKVED',
}

interface FetchPublicInfo {
	type: PublicType.FETCH_PUBLIC_INFO;
}
interface FetchPublicUserProjectInfo {
	type: PublicType.FETCH_PUBLIC_USER_PROJECT_INFO;
	unc_sort: string;
}
interface FetchPublicUncThDescriptions {
	type: PublicType.FETCH_PUBLIC_UNC_DESCRIPTIONS;
}
interface GetPurchasesMethods {
	type: PublicType.GET_PURCHASES_METHODS;
}
interface SetPurchasesMethods {
	type: PublicType.SET_PURCHASES_METHODS;
	purchasesMethods: string[];
}
interface GetPurchasesStages {
	type: PublicType.GET_PURCHASES_STAGES;
}
interface SetPurchasesStages {
	type: PublicType.SET_PURCHASES_STAGES;
	purchasesStages: string[];
}
interface GetPurchasesOkpd {
	type: PublicType.GET_PURCHASES_OKPD;
}
interface SetPurchasesOkpd {
	type: PublicType.SET_PURCHASES_OKPD;
	purchasesOkpd: string[];
}
interface GetPurchasesOkved {
	type: PublicType.GET_PURCHASES_OKVED;
}
interface SetPurchasesOkved {
	type: PublicType.SET_PURCHASES_OKVED;
	purchasesOkved: string[];
}

interface SetPublicInfo {
	type: PublicType.SET_PUBLIC_INFO;
	policesGipro: boolean;
	policesRGCO: boolean;
	filial: IFilial[];
	groups: IGroup[];
	company: ICompany[];
	regions: IRegion[];
	uncMains: IUncMain[];
	publicationDates: string[];
	purchasePublishDates: string[];
	yearBegin: string;
	yearEndOffer: string;
	uncSections: any[];
	uncThs: IUncThs[];
	uncThDescriptions: IUncThDescriptions[];
	rgStatuses: IRgStatuses[];
}
interface SetPublicUserProjectInfo {
	type: PublicType.SET_PUBLIC_USER_PROJECT_INFO;
	uncMains: IUncMain[];
	uncSections: any[];
	uncThs: IUncThs[];
}

interface SetPublicUncTechnicalSpecifications {
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS;
	uncTechnicalSpecifications: {
		name: {};
		paramName: {};
		param: {};
	};
}

const fetchPublicInfo = () => ({
	type: PublicType.FETCH_PUBLIC_INFO,
})

const fetchPublicUserProjectInfo = (unc_sort: string) => ({
	type: PublicType.FETCH_PUBLIC_USER_PROJECT_INFO,
	unc_sort,
})

const fetchPublicuncThDescriptions = () => ({
	type: PublicType.FETCH_PUBLIC_UNC_DESCRIPTIONS,
})

const setPublicInfo = () => ({
	type: PublicType.SET_PUBLIC_INFO,
})
const setPublicUserProjectInfo = () => ({
	type: PublicType.SET_PUBLIC_USER_PROJECT_INFO,
})

const setPublicUncTechnicalSpecifications = (uncTechnicalSpecifications: { name: {}; paramName: {}; param: {} }) => ({
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS,
	uncTechnicalSpecifications,
})

const getPurchasesMethods = () => ({
	type: PublicType.GET_PURCHASES_METHODS,
})
const setPurchasesMethods = (purchasesMethods: string[]) => ({
	type: PublicType.SET_PURCHASES_METHODS,
	purchasesMethods,
})

const getPurchasesStages = () => ({
	type: PublicType.GET_PURCHASES_STAGES,
})
const setPurchasesStages = (purchasesStages: string[]) => ({
	type: PublicType.SET_PURCHASES_STAGES,
	purchasesStages,
})

const getPurchasesOkpd = () => ({
	type: PublicType.GET_PURCHASES_OKPD,
})
const setPurchasesOkpd = (purchasesOkpd: string[]) => ({
	type: PublicType.SET_PURCHASES_OKPD,
	purchasesOkpd,
})

const getPurchasesOkved = () => ({
	type: PublicType.GET_PURCHASES_OKVED,
})
const setPurchasesOkved = (purchasesOkved: string[]) => ({
	type: PublicType.SET_PURCHASES_OKVED,
	purchasesOkved,
})

export type PublicTypes =
	| FetchPublicInfo
	| FetchPublicUserProjectInfo
	| FetchPublicUncThDescriptions
	| SetPublicInfo
	| SetPublicUserProjectInfo
	| SetPublicUncTechnicalSpecifications
	| SetPurchasesMethods
	| GetPurchasesMethods
	| SetPurchasesStages
	| GetPurchasesStages
	| SetPurchasesOkpd
	| GetPurchasesOkpd
	| SetPurchasesOkved
	| GetPurchasesOkved

export {
	PublicType,
	fetchPublicInfo,
	fetchPublicUserProjectInfo,
	fetchPublicuncThDescriptions,
	setPublicInfo,
	setPublicUserProjectInfo,
	setPublicUncTechnicalSpecifications,
	getPurchasesMethods,
	setPurchasesMethods,
	getPurchasesStages,
	setPurchasesStages,
	getPurchasesOkpd,
	setPurchasesOkpd,
	getPurchasesOkved,
	setPurchasesOkved,
}
