import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'

export const getAuthUserToken = () => ({
	type: AdminPanelType.GET_AUTH_USER_TOKEN,
})
export const getUsersFetch = () => ({
	type: AdminPanelType.GET_USERS_FETCH,
})

export const getUserRoleFetch = (id: number) => ({
	type: AdminPanelType.GET_USER_ROLE_FETCH,
	id,
})

export const setUserRoleFetch = (userId: number, role: number) => ({
	type: AdminPanelType.SET_USER_ROLE_FETCH,
	userId,
	role,
})

export const loadingUsers = (loading: boolean) => ({
	type: AdminPanelType.LOADING_USERS,
	loading,
})

export const loadingSettings = (loading: boolean) => ({
	type: AdminPanelType.LOADING_SETTINGS,
	loading,
})

export const setUsers = (users: IUser[]) => ({
	type: AdminPanelType.SET_USERS,
	users,
})

export const createUsers = (user: IUser) => ({
	type: AdminPanelType.CREATE_USER,
	user,
})

export const setUser = (user: unknown) => ({
	type: AdminPanelType.SET_USER,
	user,
})

export const checkUserInfo = (id: number) => ({
	type: AdminPanelType.CHECK_USER_INFO,
	id,
})

export const deleteUserFetch = (id: number) => ({
	type: AdminPanelType.DELETE_USER,
	id,
})
export const updateUserInfo = (
	id: number,
	user: IUser,
	date_start: number,
	date_end: number,
	access: boolean,
	idUserAccess?: number | undefined
) => ({
	type: AdminPanelType.UPDATE_USER_INFO_FETCH,
	id,
	user,
	date_start,
	date_end,
	access,
	idUserAccess,
})
export const updateUserIpr = (id: number, user: IUser, iprIds: number[]) => ({
	type: AdminPanelType.UPDATE_USER_IPRS,
	id,
	user,
	iprIds,
})

export const updateCompanyInfo = (company: any) => ({
	type: AdminPanelType.UPDATE_COMPANY,
	company,
})

export const createCompany = (company: any) => ({
	type: AdminPanelType.CREATE_COMPANY,
	company,
})

export const getCompanyGroupsFetch = () => ({
	type: AdminPanelType.GET_COMPANY_FETCH,
})

export const setCompanies = (companies: any) => ({
	type: AdminPanelType.SET_COMPANY,
	companies,
})

export const setOpenModalCreateCompany = (modal: IOpenModalCreateCompany) => ({
	type: AdminPanelType.SET_OPEN_CREATE_COMPANY,
	modal,
})

export const setCompanyState = (company: ICompany | null) => ({
	type: AdminPanelType.SET_COMPANY_STATE,
	company,
})
