import React, { ReactText } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Parameters } from '@root-gipro/modules/commonProjects/components'
import { IState } from '@root-gipro/store/interfaces/state'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import { IRegion } from '@root-gipro/store/interfaces'
import {
	editToggleType,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
} from '@root-gipro/modules/commonProjects/store/actions'
import Loader from '@root-gipro/shared/Loader/Loader'

const ParametersContainer: React.FC = () => {
	const { selectedProject, toggleType, idAddedUserProject } = useSelector((state: IState) => state.commonProjects)
	const { groups, regions, policesRGCO, policesGipro, rgStatuses } = useSelector((state: IState) => state.public)
	const dispatch = useDispatch()

	const group: number | undefined = groups && searchByKey(groups, 'id', selectedProject?.groupId)
	const region: IRegion | undefined = regions?.find((region: IRegion) => region.id === selectedProject?.firstRegionId)

	return selectedProject ? (
		<Parameters
			rgStatuses={rgStatuses}
			project={selectedProject}
			toggleType={toggleType}
			group={group !== undefined && groups[group]}
			region={region && region?.name}
			editToggleType={(toggleType: string) => dispatch(editToggleType(toggleType))}
			downloadCommonProject={(id: ReactText, code: string) => dispatch(downloadCommonProject(id, code))}
			addToUserProject={(projectId: ReactText) => dispatch(addToUserProject(projectId))}
			requestRgStatuses={(projectId: ReactText) => dispatch(requestRgStatuses(projectId))}
			policesRGCO={policesRGCO}
			policesGipro={policesGipro}
			idAddedUserProject={idAddedUserProject}
		/>
	) : (
		<Loader />
	)
}

export default ParametersContainer
