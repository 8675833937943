import '@root-gipro/modules/commonProjects/styles/RangePriceFilter.scss'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	filtersRequest,
	refreshFilterState,
	resetFilterState,
	setInitFilterState,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { Filter } from '@root-gipro/modules/purchases/containers'
import '@root-gipro/modules/purchases/styles/PurchasesContainer.scss'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import { IState } from '@root-gipro/store/interfaces/state'
import { decode, encode } from '@root-gipro/utils/helpers/queryString'
import { isEqual } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const FilterContainer: FC = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { purchasesType } = useSelector((state: IState) => state.purchases)
	const uncInfoCommonProjects = useSelector((state: IState) => state.commonProjects.uncInfo)

	const url: string = location.pathname.slice(1)

	const { getInitList, showFilterResult } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'common-projects'
			? filter.commonProjects
			: url === 'user-projects'
			? filter.userProjects
			: url === 'user-archive'
			? filter.userArchive
			: filter.userProjects

	const { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)
	const publicInfo = useSelector((state: IState) => state.public)

	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	useEffect(() => {
		if (showFilterState && location.search !== '' && isEqual(resultFilterState, {})) {
			dispatch(setInitFilterState(decode(location.search))) // Бесполезный dispatch. На данный момент в сагах закомментированны функции
		}
	}, [showFilterResult, publicInfo])
	const refresh = (fieldName: string, value: string) => {
		dispatch(
			refreshFilterState(
				fieldName,
				value,
				url,
				convertFilterValue(
					publicInfo.filial,
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)
	}

	const showResult = () => {
		history.push({
			pathname: location.pathname,
			search: encode(showFilterState),
		})
		if (Number(countResult)) dispatch(showFilterResultHandler(url, true))
	}
	const reset = () => {
		dispatch(getInitList!())
		dispatch(resetFilterState(url))
		dispatch(filtersRequest(url))
		history.push({
			pathname: location.pathname,
			search: '',
		})
	}
	return (
		<div className='aside-filter-container'>
			<LoaderContainer isLoading={!getInitList}>
				{getInitList && (
					<Filter
						showFilterResult={showResult}
						refreshFilterState={refresh}
						resetFilter={reset}
						//@ts-ignore
						filterCountResult={countResult}
						filterState={resultFilterState}
						purchasesType={purchasesType}
						uncInfo={uncInfoCommonProjects}
						{...publicInfo}
						rgStatuses={rgStatuses}
					/>
				)}
			</LoaderContainer>
			<div className='aside-filter-copyright'>
				{`Copyright ${new Date().getFullYear()} ©`}
				<br />
				ООО «Мастерская цифровых решений»
			</div>
		</div>
	)
}

export default FilterContainer
